import { encodeQueryItem } from 'ufo';
import { type ProviderGetImage } from '@nuxt/image';
// @ts-ignore
import { createOperationsGenerator } from '#image';

const operationsGenerator = createOperationsGenerator({
	keyMap: {
		width: 'width',
		height: 'height',
		dpr: 'dpr',
		fit: 'fit',
		gravity: 'g',
		quality: 'q',
		format: 'format',
		sharpen: 'sharpen',
	},
	valueMap: {
		fit: {
			cover: 'cover',
			contain: 'contain',
			fill: 'scale-down',
			outside: 'crop',
			inside: 'pad',
		},
		gravity: {
			auto: 'auto',
			side: 'side',
		},
	},
	joinWith: ',',
	formatter: (key: any, val: any) => encodeQueryItem(key, val),
});

const defaultModifiers = {};

// https://developers.cloudflare.com/images/image-resizing/url-format/
export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}) => {
	delete modifiers.quality;
	const mergeModifiers = {
		...defaultModifiers,
		...modifiers,
	};
	const operations = operationsGenerator(mergeModifiers as any).replace(
		',',
		'&',
	);
	// e.g., https://cdn.shopify.com/static/
	// if src already has query params, append & to the end
	const url = src.includes('?')
		? `${src}&${operations}`
		: `${src}?${operations}`;

	return {
		url,
	};
};
